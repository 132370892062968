import React from "react";
import "twin.macro";
import { graphql } from "gatsby";
import { PageLayout } from "../components";

const Privacy = () => {
  return <PageLayout title="Privacy Page"></PageLayout>;
};

export default Privacy;
